import Flux from '../../../flux/Flux';

const BREAKPOINTS_SMALL_TO_LARGE = Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE;

/**
 * Multiple Grid2 props allow passing different values for different breakpoints. For simplicity, they also accept a single primitive value that is applied for all sizes.
 *
 * This class provides a way to deal with this data in an easy way.
 */
export default class ModelBreakpointObject {
	/**
	 * @param {object|*} value
	 */
	constructor(value) {
		if (typeof value === 'object') {
			if (value instanceof ModelBreakpointObject) {
				this.values = {...value.values};
			} else {
				this.values = {};

				for (const breakpoint of BREAKPOINTS_SMALL_TO_LARGE) {
					const breakpointValue = value[breakpoint];

					if (typeof breakpointValue !== 'undefined') {
						this.values[breakpoint] = breakpointValue;
					}
				}
			}
		} else {
			this.values = {xs: value};
		}
	}

	/**
	 * @param {string} breakpoint
	 * @return {boolean}
	 */
	hasValueForBreakpoint(breakpoint) {
		return typeof this.values[breakpoint] !== 'undefined';
	}

	/**
	 * @param {string} breakpoint
	 * @return {*}
	 */
	getValueAtBreakpoint(breakpoint) {
		const index = BREAKPOINTS_SMALL_TO_LARGE.indexOf(breakpoint);

		for (let i = index; i >= 0; i--) {
			const value = this.values[BREAKPOINTS_SMALL_TO_LARGE[i]];

			if (typeof value !== 'undefined') {
				return value;
			}
		}
	}

	/**
	 * @param {ModelBreakpointObject} breakpointObject
	 * @return {boolean}
	 */
	isEqualTo(breakpointObject) {
		for (const breakpoint of BREAKPOINTS_SMALL_TO_LARGE) {
			if (this.values[breakpoint] !== breakpointObject.values[breakpoint]) {
				return false;
			}
		}

		return true;
	}
}