import Flux from "../flux/Flux";
import ModelBreakpointObject from "../components/Grid2/Models/ModelBreakpointObject";

export default {
	/**
	 * @param {string} breakpoint
	 * @return {string}
	 */
	getCssBreakpointPrefixByBreakpoint(breakpoint) {
		return breakpoint === Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE[0] ? '' : `${breakpoint}-`;
	},

	/**
	 *
	 * @param {ModelBreakpointObject} tileSize
	 * @returns {string}
	 */
	getSizeClasses(tileSize) {
		const classes = [];

		let breakpoint;
		for (breakpoint of Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE) {
			if (tileSize.hasValueForBreakpoint(breakpoint)) {
				const size   = tileSize.getValueAtBreakpoint(breakpoint);
				const prefix = this.getCssBreakpointPrefixByBreakpoint(breakpoint);

				classes.push(`-size-${prefix}${size}`);
			}
		}

		return classes.join(' ');
	},

	/**
	 *
	 * @param {ModelBreakpointObject} tileSize
	 * @param {ModelBreakpointObject} columns
	 * @returns {string}
	 */
	getExtendedSizeClasses(tileSize, columns) {
		const classes = [];

		for (const breakpoint of Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE) {
			if (tileSize.hasValueForBreakpoint(breakpoint) || columns.hasValueForBreakpoint(breakpoint)) {
				const size         = tileSize.getValueAtBreakpoint(breakpoint);
				const columnsCount = columns.getValueAtBreakpoint(breakpoint);
				const prefix       = this.getCssBreakpointPrefixByBreakpoint(breakpoint);

				classes.push(`-size-extended-${prefix}${size}-${columnsCount}`);
			}
		}

		return classes.join(' ');
	},

	/**
	 * Determines the amount of items to show if you only want to display full rows
	 * E.g if you have a grid with 11 items and 4 columns this function should return 8
	 * !Only works when all tiles have size 1!
	 *
	 * @param {Object} columns
	 * @param {string} breakpoint
	 * @param {number} itemCount
	 * @returns {number}
	 */
	getItemCountForFullRows(columns, breakpoint, itemCount) {
		columns = new ModelBreakpointObject(columns);
		const columnsCount = columns.getValueAtBreakpoint(breakpoint);

		let newItemCount = itemCount;
		if (itemCount > columnsCount) {
			newItemCount = itemCount - (itemCount % columnsCount);
		}

		return newItemCount;
	},

	/**
	 *
	 * @param {number} windowWidth
	 * @returns {string}
	 */
	getBreakpointByWindowWidth(windowWidth) {
		let currentBreakpoint;
		for (const breakpoint of Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE) {
			if (windowWidth >= Flux.Constants.Grid2.BREAKPOINT_MIN_WIDTHS[breakpoint]) {
				currentBreakpoint = breakpoint;
			}
		}

		return currentBreakpoint;
	},
};