export default class ModelTileClasses {
	/**
	 * @param {{index: number, tileClass: function}|({index: number, tileClass: function})[]} tileClasses
	 * @param {object} defaultTileClass
	 */
	constructor(tileClasses, defaultTileClass) {
		this.tileClasses      = Array.isArray(tileClasses) ? tileClasses : [tileClasses];
		this.defaultTileClass = defaultTileClass;
	}

	/**
	 *
	 * @param {number} index
	 * @return {function}
	 */
	getTileClassAtIndex(index) {
		for (const value of this.tileClasses) {
			if (value.index === index) {
				return value.tileClass;
			}
		}

		return this.defaultTileClass;
	}
}