import ModelBreakpointObject from "./ModelBreakpointObject";

export default class ModelTileSizes {
	/**
	 * @param {{index: number, size: number|object}|({index: number, size: number|object})[]} sizes
	 * @param {ModelBreakpointObject} defaultSize
	 */
	constructor(sizes, defaultSize) {
		const sizesAsArray = Array.isArray(sizes) ? sizes : [sizes];
		this.sizes         = sizesAsArray.map(({index, size}) => ({index, size: new ModelBreakpointObject(size)}));
		this.defaultSize   = defaultSize;
	}

	/**
	 *
	 * @param {number} index
	 * @return {ModelBreakpointObject}
	 */
	getTileSizeAtIndex(index) {
		for (const definition of this.sizes) {
			if (definition.index === index) {
				return definition.size;
			}
		}

		return this.defaultSize;
	}
}