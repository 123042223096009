export default class ModelTileConfigs {
	/**
	 * @param {{index: number, config: object}|({index: number, config: object})[]} configs
	 * @param {object} defaultConfig
	 */
	constructor(configs, defaultConfig) {
		this.configs       = Array.isArray(configs) ? configs : [configs];
		this.defaultConfig = defaultConfig;
	}

	/**
	 *
	 * @param {number} index
	 * @return {object}
	 */
	getConfigAtIndex(index) {
		for (const value of this.configs) {
			if (value.index === index) {
				return value.config;
			}
		}

		return this.defaultConfig;
	}
}